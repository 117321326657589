import { React, useState, useContext, useEffect, useRef, Link, Fragment } from 'react';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import { Box, Paper } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer } from '@mui/material';
import { Button, TextField, Select, MenuItem, InputLabel, FormHelperText } from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import { OrderContext } from './OrderContext';
import OrderContextProvider from './OrderContext';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import axios from 'axios';
import Simplert from "../../components/Simplert";

import DynamicRows from "./DynamicRows";
import banner from '../../images/banner.png';
import orderWay from '../../images/索書方式2.png';
import fazangBook from '../../images/法藏文化叢書_更新.png';
import donate from '../../images/邀請您隨喜助印，贊助各項弘化推廣工作！2.png';
import announce from '../../images/觀音山-法藏文化結緣-國際索書郵資公告.jpg';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  background: '#f5f6fa',
}));

const StyledCard = styled(Card)({
  marginBottom: 30
});

// const useStyles = makeStyles((theme) => ({
//   container: {
//     paddingTop: theme.spacing(4),
//     paddingBottom: theme.spacing(4),
//     //paddingLeft: theme.spacing(1),
//     //paddingRight: theme.spacing(1),
//     background: '#f5f6fa',
//   },
//   table: {
//     '& tbody>.MuiTableRow-root:hover': {
//       background: '#f5f6fa',
//     }
//   },
//   detailHead: {
//     '& > *': {
//       fontWeight: 'bold',
//       borderBottom: 'unset',
//     },
//   },
//   searchFormControl: {
//     margin: theme.spacing(1),
//   },
//   input: {
//     minWidth: 250,
//     margin: theme.spacing(1)
//   },

//   select: {
//     margin: theme.spacing(1),
//     minWidth: 130
//   },

//   addRow: {
//     float: 'right'
//   },
//   title: {
//     background: '#125887',
//     '& h2': {
//       color: 'white',
//     }
//   },
//   formStyle: {
//     '& > *': {
//       borderBottom: 'unset',
//     },
//   },
//   submit_confirm_title: {
//     background: '#125887',
//     '& h2': {
//       color: 'white',
//     }
//   },
//   card: {
//     marginBottom: 30
//   },
//   tableHead: {
//     backgroundColor: '#cad8e3'
//   },

// }));

const Order = () => {
  const {
    contextDynamicData,
    setContextDynamicData
  } = useContext(OrderContext);

  // for Simplert
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMsg, setAlertMsg] = useState('');

  const [nameError, setNameError] = useState(false);
  const [telError, setTelError] = useState(false);
  const [bookError, setBookError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [otherError, setOtherError] = useState(false);
  const [submit, setSubmit] = useState(false);

  const receiverRef = useRef();
  const receiverTelRef = useRef();
  const localDestRef = useRef();
  const foreignDestRef = useRef();
  const receiverEmailRef = useRef();
  const otherRef = useRef();

  const handleRedirect = () => {
    setSubmit(false);

    // 先清空
    const _tmpRows = [...contextDynamicData];
    _tmpRows.splice(0, _tmpRows.length)
    setContextDynamicData(_tmpRows);
    // 再新增一筆空的
    //setContextDynamicData(prevData => [...prevData, { allField: '', category: '', id: '', bookId: '', qty: '0' }]);
  }

  const handleSubmit = () => {
    const postData = async () => {
      const result = await axios.post(
        process.env.REACT_APP_API_ENDPOINT + '/api/Order/',
        {
          'receiver': receiverRef.current.value,
          'receiverTel': receiverTelRef.current.value,
          'local': localDestRef.current.value,
          'foreign': foreignDestRef.current.value,
          'email': receiverEmailRef.current.value,
          'other': otherRef.current.value,
          'subItem': contextDynamicData
        },
      ).then(response => {
        if (!response.data.succ) {
          if (response.data.code === '200') {
            setAlertType('warning');
            setAlertTitle('錯誤訊息');
            setAlertMsg(response.data.message);
          }
          else {
            setAlertType('error');
            setAlertTitle('系統發生錯誤');
            setAlertMsg(response.data.message);
          }
          setShowAlert(true);
        }
        else
          setSubmit(true);
      });
    };

    if (receiverRef.current.value === '') {
      setNameError(true);
      setAlertType('error');
      setAlertTitle('請填寫您的姓名');
      setAlertMsg('');
      setShowAlert(true);
      return;
    }
    else
      setNameError(false);

    if (receiverTelRef.current.value === '') {
      setTelError(true);
      setAlertType('error');
      setAlertTitle('請填寫您的聯絡電話');
      setAlertMsg('');
      setShowAlert(true);
      return;
    }
    else
      setTelError(false);

    if (localDestRef.current.value === '' && foreignDestRef.current.value === '') {
      setAlertType('error');
      setAlertTitle('請填寫您的寄送地址');
      setAlertMsg('');
      setShowAlert(true);
      return;
    }

    if (foreignDestRef.current.value !== '' && receiverEmailRef.current.value === '') {
      setEmailError(true);
      setAlertType('error');
      setAlertTitle('請填寫您的Email');
      setAlertMsg('');
      setShowAlert(true);
      return;
    }
    else
      setEmailError(false);

    // if (otherRef.current.value === '' && contextDynamicData.length === 0) {
    //   setOtherError(true);
    //   setAlertType('error');
    //   setAlertTitle('請填寫您要索取的其他法寶及數量');
    //   setAlertMsg('');
    //   setShowAlert(true);
    //   return;
    // }
    // else
    //   setOtherError(false);

    // if (contextDynamicData.length === 0
    //   && otherRef.current.value.replace(/\s+/g, '').indexOf('親近上師') === -1
    //   && otherRef.current.value.replace(/\s+/g, '').indexOf('密勒日巴') === -1
    //   && otherRef.current.value.replace(/\s+/g, '').indexOf('上師五十法') === -1
    //   && otherRef.current.value.replace(/\s+/g, '').indexOf('地震') === -1
    //   && otherRef.current.value.replace(/\s+/g, '').indexOf('咒輪') === -1
    //   && otherRef.current.value.replace(/\s+/g, '').indexOf('大乘禪修引導') === -1)  {
    //   setBookError(true);
    //   setAlertType('error');
    //   setAlertTitle('請填寫您要索取的善書經本及冊數');
    //   setAlertMsg('');
    //   setShowAlert(true);
    //   return;
    // }
    if (contextDynamicData.length !== 0) {
      for (var i = 0; i < contextDynamicData.length; i++) {
        if (contextDynamicData[i].bookId === '' || contextDynamicData[i].qty <= 0) {
          setBookError(true);
          setAlertType('error');
          setAlertTitle('請填寫您要索取的佛經善書及冊數');
          setAlertMsg('');
          setShowAlert(true);
          return;
        }
        else
          setBookError(false);
      }
    }
    postData();
  };

  return (
    <StyledContainer maxWidth="md">
      <StyledCard sx={{ maxWidth: "md" }}>
        <CardMedia
          style={{
            width: "100%",
            maxHeight: "300px"
          }}
          component="img"
          image={banner}
          alt="banner image"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            觀音山 法藏文化叢書 法寶免費結緣
          </Typography>
          {!submit ?
            <Typography variant="body2" color="textPrimary">
              歡迎透過此表單索取法寶，並註明以下必填項目，<br />
              1.姓名、2.電話、3.收件地址、4.索取之『書籍名稱+冊數』。<br />
              <br />
              亦歡迎來電或傳真或Mail方式告知，<br />
              ◎電話：+886 4 22053818 ◎傳真：+886 4 22053798<br />
              ◎Mail：<a href='mailto:fazang.buddha@gmail.com'>fazang.buddha@gmail.com</a><br />
              <br />
              瀏覽👉👉【法藏文化叢書系列】👈👈<br />
              <a href='https://www.fazang.org/index.php?ID=611&COM_NAME_1_s=1#'>https://www.fazang.org/index.php?ID=611&COM_NAME_1_s=1#</a><br />
              <br />
              ★ 希望法寶能廣泛流通，三寶所依物請勿轉售、作為商業用途。當您閱讀後，可將此好書與更多人結緣，將良善的觀念、正能量傳遞出去。<br />
              <br />
              ◆本佛教會致力弘揚正法，推廣好書淨化人心，長期免費結緣法寶。因應廣大法友索書需求，在此說明呼籲索取佛經善書，
              <FormControl fullWidth variant="standard">
                <FormHelperText id="component-helper-text" sx={{ fontSize: 14, fontWeight: 'bold', color: '#e37210' }}>『請以個人讀閱為主，不需要將觀音山法寶放置宮廟或是其他公共場合、店家結緣』，</FormHelperText>
              </FormControl>
              感謝您的配合，阿彌陀佛！<br />

              <br />
              <br />
              ◆觀音山 中華大悲法藏佛教會 全球資訊網 <a href='https://www.fazang.org'>www.fazang.org</a> <br />
              ◆訂閱法藏影音，恭聽尊貴 龍德上師佛法開示：法藏Youtube頻道  <a href='https://ppt.cc/YQ5E'>ppt.cc/YQ5E</a><br />
            </Typography>
            :
            <Typography variant="body2" color="textPrimary">
              我們已經收到您回覆的表單，謝謝您！<br />
              <br />
              注意事項：<br />
              1、索書寄送作業時間約為5～7個工作天(不含例假日)。<br />
              2、請領的書目、數量、寄件資訊等，會有專人與您聯繫。<br />
              3、有任何索取的問題，歡迎來電洽詢。洽詢電話：04-22053818<br />
            </Typography>
          }
        </CardContent>
      </StyledCard>
      {!submit ?
        <Fragment>
          {/* */} <StyledCard sx={{ maxWidth: "md" }}>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
              <span style={{backgroundColor: "#FF8000", color: "white"}}>【觀音山法寶寄送公告】</span>
              </Typography>
              <Typography variant="body3" color="textPrimary">
              觀音山的法友大德們：阿彌陀佛！<br />
              <span style={{fontWeight: "bold"}}>農曆年前【觀音山 印贈經會】開放申請法寶服務 (以及專屬信箱回覆) 至<span style={{color: "blue"}}>1月22日(三)</span></span>
              ，法友大德如有需要，敬請盡早填寫「觀音山 法藏文化叢書 法寶免費結緣申請單」；
              <span style={{fontWeight: "bold"}}>年後<span style={{color: "blue"}}>2月5日(三)</span>起，再為大家持續服務與寄送。</span><br />
              <br />
              祝福大家新年如意，諸事圓滿、六時吉祥！
              </Typography>
            </CardContent>
          </StyledCard>
          <StyledCard sx={{ maxWidth: "md" }}>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                索書方式
              </Typography>
            </CardContent>
            <CardMedia
              style={{
                width: "70%",
                maxHeight: "70%",
                marginLeft: "auto",
                marginRight: "auto"
              }}
              component="img"
              image={orderWay}
              alt="banner image"
            />
          </StyledCard>
          <StyledCard sx={{ maxWidth: "md" }}>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                國際索書郵資公告
              </Typography>
            </CardContent>
            <CardMedia
              style={{
                width: "70%",
                maxHeight: "70%",
                marginLeft: "auto",
                marginRight: "auto"
              }}
              component="img"
              image={announce}
              alt="banner image"
            />
            <CardContent>
              <Typography gutterBottom variant="body2" component="div" sx={{ width: '73%', marginLeft: 'auto', marginRight: 'auto' }}>
                中華郵政「緊急情況附加費資費表」公告：<br />
                <a href='https://reurl.cc/N02ZV6' target='_blank'>https://reurl.cc/N02ZV6</a>
              </Typography>
            </CardContent>
          </StyledCard>
          <StyledCard>
            <CardContent>
              <FormControl fullWidth variant="standard">
                <TextField
                  required
                  label="您的姓名(必填)"
                  error={nameError}
                  inputRef={receiverRef}
                  variant="standard"
                />
                <FormHelperText id="component-helper-text">國外法友，請填寫「中文+英文全名」，例：王大偉(WANG,DA-WEI)。</FormHelperText>
              </FormControl>
            </CardContent>
          </StyledCard>
          <StyledCard>
            <CardContent>
              <FormControl fullWidth>
                <TextField
                  required
                  label="聯絡電話(必填)"
                  error={telError}
                  inputRef={receiverTelRef}
                  variant="standard"
                />
              </FormControl>
            </CardContent>
          </StyledCard>
          <StyledCard>
            <CardContent>
              <FormControl fullWidth variant="standard">
                <TextField
                  label="國內寄送地址"
                  placeholder="請填寫"
                  inputRef={localDestRef}
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <FormHelperText id="component-helper-text">含澎、金、馬地區，郵遞區號盡量填寫五碼。</FormHelperText>
              </FormControl>
            </CardContent>
          </StyledCard>
          <StyledCard>
            <CardContent>
              <FormControl fullWidth variant="standard">
                <TextField
                  label="國外寄送地址"
                  placeholder="請填寫"
                  inputRef={foreignDestRef}
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <FormHelperText id="component-helper-text">請填寫國名及正確完整地址，須用英文或寄達國所接受之語文填寫。</FormHelperText>
              </FormControl>
            </CardContent>
          </StyledCard>
          <StyledCard>
            <CardContent>
              <FormControl fullWidth variant="standard">
                <TextField
                  label="您的Email（選擇國外寄送，請必填）"
                  error={emailError}
                  placeholder="請填寫"
                  inputRef={receiverEmailRef}
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <FormHelperText id="component-helper-text">若為國際郵遞，收到您的索書申請時，會先寄email與您核對相關資訊。收到您的「回信確認」後，我們才會寄至您所留的地址資訊，敬請協助，謝謝。</FormHelperText>
              </FormControl>
            </CardContent>
          </StyledCard>
          <StyledCard>
            <CardContent>
              <FormControl fullWidth variant="standard">
                <FormHelperText id="component-helper-text" sx={{ fontSize: 14, fontWeight: 'bold' }}> ◆本佛教會致力弘揚正法，推廣好書淨化人心，長期免費結緣法寶。因應廣大法友索書需求，在此說明呼籲索取佛經善書，</FormHelperText>
                <FormHelperText id="component-helper-text" sx={{ fontSize: 14, fontWeight: 'bold', color: '#e37210' }}>『請以個人讀閱為主，不需要將觀音山法寶放置宮廟或是其他公共場合、店家結緣』，</FormHelperText>
                <FormHelperText id="component-helper-text" sx={{ fontSize: 14, fontWeight: 'bold' }}> 感謝您的配合，阿彌陀佛！</FormHelperText><br />
                <FormHelperText id="component-helper-text" sx={{ fontSize: 14, fontWeight: 'bold', color: '#4976d1' }}>索取佛經、善書、光碟 (請按「新增項目」)</FormHelperText>
                <DynamicRows />
              </FormControl>
            </CardContent>
            <CardContent>
              <FormControl fullWidth variant="standard">
                <TextField
                  label="索取其他法寶(例如：佛卡、小卡、咒貼)及數量"
                  placeholder="請填寫"
                  inputRef={otherRef}
                  error={otherError}
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </CardContent>
          </StyledCard>
          <StyledCard sx={{ maxWidth: "md" }}>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                法藏文化叢書
              </Typography>
            </CardContent>
            <CardMedia
              style={{
                width: "70%",
                maxHeight: "70%",
                marginLeft: "auto",
                marginRight: "auto"
              }}
              component="img"
              image={fazangBook}
              alt="banner image"
            />
          </StyledCard>
          <StyledCard sx={{ maxWidth: "md" }}>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                護持捐款方式
              </Typography>
            </CardContent>
            <CardMedia
              style={{
                width: "70%",
                maxHeight: "70%",
                marginLeft: "auto",
                marginRight: "auto"
              }}
              component="img"
              image={donate}
              alt="banner image"
            />
          </StyledCard>
          <Button
            variant="outlined"
            startIcon={<AssignmentTurnedInIcon />}
            onClick={handleSubmit}
          >
            提交
          </Button>



          <Simplert
            showSimplert={showAlert}
            type={alertType}
            title={alertTitle}
            message={alertMsg}
            customCloseBtnText='關閉'
            onClose={() => setShowAlert(!showAlert)}
          />
        </Fragment>
        :
        <Button
          variant="outlined"
          startIcon={<AssignmentTurnedInIcon />}
          onClick={handleRedirect}
        >
          繼續申請其他索書
        </Button>
      }
    </StyledContainer>
  )
}

export default Order;